import React from 'react'
import { Menu, Dropdown, Button, Modal } from 'antd'
import { BannerStatus } from 'consts'

const { confirm } = Modal

const BannerStatusMenu: React.FC<{
  onClick: (key: number) => void
  loading: boolean
  currentStatus: number
}> = ({ onClick, loading, currentStatus }) => {
  const menu = (
    <Menu
      onClick={({ key }) => {
        confirm({
          title: 'Warning',
          content:
            'Are you sure you want to change the status of this/these banner(s) ?',
          okText: 'Yes',
          okType: 'danger',
          cancelText: 'No',
          onOk: () => onClick(Number(key)),
        })
      }}
    >
      <Menu.Item key={BannerStatus.PENDING}>Pending</Menu.Item>
      <Menu.Item key={BannerStatus.CLEANED}>Cleaned (Not a banner)</Menu.Item>
      {currentStatus === BannerStatus.INSERTED && (
        <Menu.Item key={BannerStatus.APPROVED}>
          Approved (To be Inserted)
        </Menu.Item>
      )}
    </Menu>
  )
  return (
    <Dropdown trigger={['click']} overlay={menu} placement="bottomLeft">
      <Button autoFocus={true} tabIndex={1} loading={loading} type="danger">
        Change Status
      </Button>
    </Dropdown>
  )
}

export default BannerStatusMenu
