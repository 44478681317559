import React, { useMemo } from 'react'
import RVSelect from 'components/RVSelect'
import { Button, Checkbox, Form, InputNumber } from 'antd'
import _ from 'lodash'
import useMasterData from './useMasterData'
import { validationSchema } from './useForm'

const InsertionForm: React.FC<{
  formik: any
  onSubmit: (action: string) => () => Promise<void>
  countries: number[]
}> = ({ formik, onSubmit, countries }) => {
  const { brands, categories, bannerTypes } = useMasterData(countries)

  const setValue = (fieldName: string, type: string) => (e: any) => {
    let value
    switch (type) {
      case 'checkbox':
        value = e.target.checked
        break
      case 'select':
        value = e
        break
      case 'text':
        value = e
    }
    formik.setFieldValue(fieldName, value)
  }

  const countryCategories = useMemo(() => {
    if (!categories) return []
    return countries.length <= 0
      ? categories
      : categories.filter((c: any) => countries.includes(+c.country))
  }, [categories, countries])

  const isValid = validationSchema.isValidSync(formik.values, {
    abortEarly: false,
  })

  return (
    <Form
      labelCol={{ xs: { span: 22 }, sm: { span: 6 } }}
      wrapperCol={{ xs: { span: 26 }, sm: { span: 18 } }}
    >
      <Form.Item label="Brands">
        <RVSelect
          tabIndex={2}
          options={brands}
          multi={true}
          value={formik.values.groups_brands}
          onChange={setValue('groups_brands', 'select')}
          valueKey="id"
        />
        {formik.touched.groups_brands &&
          formik.errors.groups_brands &&
          formik.errors.groups_brands.length !== 0 && (
            <span style={{ color: 'red' }}>Please select group/brands</span>
          )}
      </Form.Item>
      <Form.Item label="Categories">
        <RVSelect
          tabIndex={3}
          options={countryCategories}
          multi={true}
          labelKey={'name'}
          valueKey={'id'}
          onChange={setValue('categories', 'select')}
          value={formik.values.categories}
        />
        {formik.touched.categories && formik.errors.categories && (
          <span style={{ color: 'red' }}>{formik.errors.categories}</span>
        )}
      </Form.Item>
      <Form.Item label="Type">
        <RVSelect
          tabIndex={4}
          options={bannerTypes}
          multi={false}
          labelKey={'name'}
          valueKey={'id'}
          onChange={setValue('format', 'select')}
          value={formik.values.format}
        />
        {formik.touched.format && formik.errors.format && (
          <span style={{ color: 'red' }}>{'Must add Type'}</span>
        )}
      </Form.Item>
      <Form.Item label="Game ?">
        <Checkbox
          name="is_game"
          tabIndex={5}
          {...formik.getFieldProps('is_game')}
          value={undefined}
          checked={formik.values.is_game}
          onChange={setValue('is_game', 'checkbox')}
        />
      </Form.Item>
      <Form.Item label="Promotion ?">
        <Checkbox
          tabIndex={6}
          {...formik.getFieldProps('is_promotion')}
          value={undefined}
          checked={formik.values.is_promotion}
          onChange={setValue('is_promotion', 'checkbox')}
        />
      </Form.Item>
      <Form.Item style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <Button
          tabIndex={7}
          size="large"
          type="primary"
          icon="save"
          loading={formik.isSubmitting}
          onClick={onSubmit('next')}
          disabled={!isValid}
          htmlType="submit"
        >
          Insert & Next
        </Button>
      </Form.Item>
    </Form>
  )
}

export default InsertionForm
